import clsx from "clsx";
import css from './Parameters.module.scss'
import global from '../../../styles/Global.module.scss'
import solution from './Solutions.module.scss'
import Link from 'next/link'
import { useEffect, useRef, useState } from "react";
import useVisible from "../../../hooks/useVisible";
import { declOfNum } from '../../../data/helper'
import VisualSolutions from "../../visual/one/VisualSolutions";

const Parameters = ({ title, number, price_min, area_min, area_max }) => {

    //animation
    const ref = useRef(null)
    const { isVisible: inView } = useVisible(ref, 1)
    //

    return (
        <section className={clsx(global.section, css.apartment)} ref={ref}>
            <h2 className='main-page__title main-page__apartment' dangerouslySetInnerHTML={{ __html: title }}></h2>
            <div className={`${css.content} ${css.choice}`}>
                <div className={inView ? `main-page__item ${css.item} ${css.parameters} ${css.animation} ${css.left} ` : `main-page__item ${css.item} ${css.parameters} ${css.left} `}>
                    <div className={css.title}>Выбор <br />по параметрам</div>
                    <p>Выбирайте по цене и метражу</p>
                    <Link href={"/catalog"}>
                        <a className={`${css.button} btn`}>Выбрать квартиру</a>
                    </Link>
                    <VisualSolutions isVisual />
                </div>
                <div className={inView ? `main-page__item  ${css.visual} ${css.item} ${css.animation} ${css.right}` : `main-page__item  ${css.visual} ${css.item} ${css.right}`}>
                    <img src="/files/images/main/master-plan-main.webp" alt={""} loading={"lazy"} />
                    <div className={`${css.title} ${css.white}`}>Визуальный выбор</div>
                    <p className={css.text}>Выбирайте на генплане</p>
                    <Link href={"/vybor-kvartiri/vybor-na-genplane"}>
                        <a className={css.corpus}>
                            <div className={`${css.building} ${css.first}`}>
                                <div className={css.bubble}>
                                    <div>1</div>
                                    корпус
                                </div>
                                <div className={css.info}>{number} {declOfNum(number, ['квартира', 'квартиры', 'квартир'])} в продаже</div>
                            </div>
                        </a>
                    </Link>
                    <div className={css.data}>
                        <div className={css.cell}>
                            <p>Квартиры площадью</p>
                            <span>от {Math.floor(area_min)} до {Math.floor(area_max)} м<sup>2</sup></span>
                        </div>
                        <div className={css.cell}>
                            <p>Минимальная стоимость</p>
                            <span>{Math.ceil(price_min * 10) / 10} млн <span className={`${global.rouble} ${css.rouble}`}>₽</span></span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Parameters
